import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 526.000000 373.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,373.000000) scale(0.100000,-0.100000)">
<path d="M0 1865 l0 -1865 2630 0 2630 0 0 1865 0 1865 -2630 0 -2630 0 0
-1865z m2862 1681 c151 -33 250 -66 386 -131 149 -71 303 -178 370 -257 23
-27 28 -28 118 -28 l94 0 0 -145 0 -145 -205 0 -206 0 3 -102 3 -103 50 -2
c28 -1 112 -2 188 -2 l137 -1 0 -145 0 -145 -190 0 -190 0 0 -125 0 -125 205
0 205 0 0 -150 0 -150 -30 0 c-16 0 -30 -4 -30 -8 0 -5 -12 -14 -27 -21 -25
-11 -29 -10 -42 8 -11 17 -24 21 -63 21 -35 0 -48 -4 -45 -12 7 -22 -52 -97
-81 -104 -15 -3 -32 -7 -38 -9 -7 -2 -24 -30 -40 -62 -28 -59 -39 -143 -18
-150 6 -2 34 16 63 39 40 33 54 52 63 86 7 28 28 62 59 96 53 58 79 71 105 51
25 -18 12 -53 -45 -128 -26 -35 -51 -69 -55 -75 -10 -17 12 -52 32 -52 23 0
58 56 40 63 -8 3 -6 6 4 6 10 1 35 28 58 60 45 67 46 71 26 71 -12 0 -13 6 -5
29 6 16 17 28 26 27 9 0 28 19 43 44 27 43 28 52 33 198 3 134 2 152 -12 153
-9 1 -61 2 -116 3 l-100 1 -3 88 -3 87 101 0 100 0 0 169 c0 93 3 176 6 185 5
13 -9 16 -98 18 l-103 3 -3 63 -3 62 83 2 c46 1 97 1 114 0 30 -2 31 -1 38 44
3 26 6 100 6 165 l0 119 255 0 255 0 0 -145 0 -145 -205 0 -205 0 0 -105 0
-105 190 0 190 0 0 -145 0 -145 -160 0 -160 0 0 -114 c0 -81 -4 -117 -12 -123
-7 -4 70 -10 172 -13 l185 -5 3 -147 3 -148 -215 0 c-203 0 -216 -1 -226 -19
-14 -27 -13 -29 11 -33 12 -2 25 -10 29 -18 4 -8 12 -25 18 -37 17 -32 15 -65
-8 -125 -11 -28 -18 -54 -15 -56 12 -12 58 35 85 86 49 94 82 122 120 102 24
-13 18 -27 -37 -99 -51 -68 -39 -79 15 -14 50 60 104 111 147 139 20 13 28 14
40 4 27 -22 17 -67 -31 -136 -43 -64 -53 -103 -27 -111 6 -2 34 19 62 46 27
28 53 51 56 51 28 0 -45 -106 -119 -172 -81 -73 -128 2 -76 120 11 25 19 46
18 48 -2 1 -32 -37 -68 -84 -36 -48 -73 -88 -82 -90 -14 -3 -21 8 -30 42 -13
49 -21 55 -44 31 -9 -8 -27 -18 -42 -21 -15 -4 -38 -17 -52 -30 -41 -38 -85
-49 -116 -30 -14 10 -30 29 -35 44 l-11 27 -16 -25 c-8 -14 -56 -66 -106 -115
-223 -221 -549 -374 -889 -416 -138 -16 -404 -6 -525 20 -323 71 -591 214
-789 421 -60 62 -75 92 -53 105 6 3 10 14 10 23 0 13 -5 11 -17 -8 -9 -14 -31
-38 -49 -53 -41 -35 -66 -30 -88 17 -18 38 -20 73 -6 131 7 30 6 43 -5 55 -8
8 -28 46 -45 83 l-32 67 -42 0 c-77 0 -208 31 -193 46 2 2 31 -3 63 -11 32 -9
98 -16 145 -16 204 0 336 97 379 280 20 86 9 224 -25 299 -35 78 -84 124 -250
234 -144 96 -167 116 -190 172 -15 36 -15 54 1 109 3 9 1 17 -5 17 -6 0 -11
-6 -11 -14 0 -8 -5 -18 -12 -22 -7 -4 -8 -3 -4 4 4 6 0 26 -9 44 -10 21 -11
27 -3 16 18 -21 47 -24 81 -8 20 10 24 16 15 22 -9 5 -10 8 -1 8 6 0 15 -4 18
-10 3 -5 19 -10 35 -10 55 0 144 -109 134 -166 -3 -21 0 -21 112 -22 l116 -1
-6 62 c-10 102 -12 110 -33 157 -30 66 -82 120 -116 120 -19 0 -26 -4 -21 -12
5 -8 2 -9 -10 -5 -10 4 -31 7 -47 7 -20 0 -28 4 -23 11 4 6 19 9 38 5 27 -5
28 -4 11 6 -23 13 -191 16 -252 4 -68 -14 -85 -49 -23 -49 16 0 27 -4 24 -9
-3 -4 9 -8 25 -8 46 0 37 -15 -9 -17 -22 -1 -40 3 -40 8 0 7 -12 8 -30 4 -19
-4 -30 -3 -30 4 0 6 -9 2 -21 -10 -11 -11 -18 -27 -15 -34 8 -21 -5 -26 -28
-10 -27 19 -40 19 -32 -1 3 -8 2 -12 -4 -9 -6 3 -10 2 -10 -3 0 -16 13 -22 26
-11 8 7 14 7 19 -1 4 -6 2 -9 -4 -8 -5 2 -17 -6 -25 -17 -14 -18 -15 -18 -16
-2 0 41 -19 11 -39 -61 -24 -89 -23 -191 2 -259 8 -22 13 -44 10 -47 -10 -10
93 -102 187 -166 106 -72 234 -196 244 -235 7 -25 -14 -61 -28 -48 -3 4 -6 -1
-6 -9 0 -9 -7 -21 -16 -29 -13 -11 -10 -15 28 -29 37 -13 43 -20 46 -48 5 -45
-19 -77 -77 -107 -27 -13 -52 -29 -56 -35 -9 -14 -35 -13 -35 2 0 7 12 23 27
36 l28 23 -76 -4 c-85 -6 -175 -42 -198 -80 -12 -18 -11 -23 8 -38 12 -10 52
-27 89 -40 88 -29 115 -43 163 -86 32 -29 39 -42 39 -73 0 -132 -130 -213
-345 -214 -83 -1 -106 2 -133 19 -30 18 -33 23 -28 55 7 47 36 83 75 94 33 9
55 1 32 -13 -24 -15 55 -22 126 -11 126 20 196 51 191 83 -4 28 -44 48 -173
86 -125 37 -139 51 -133 136 4 47 1 63 -13 79 -50 53 -78 154 -79 279 l0 87
154 0 153 0 6 -79 c3 -43 11 -92 19 -108 12 -25 19 -28 70 -31 49 -3 58 0 72
20 30 42 15 141 -26 176 -11 9 -12 -2 -4 -68 10 -92 2 -110 -50 -110 -42 0
-47 14 -48 154 l-1 108 -70 49 c-100 71 -173 135 -199 178 -67 107 -78 287
-25 411 29 67 109 141 184 170 117 44 290 36 396 -18 l57 -29 35 41 c76 91
235 206 396 286 170 85 361 141 542 160 142 15 372 4 501 -24z m-1752 -476
c-16 -11 -40 -13 -40 -5 0 8 44 24 50 18 2 -2 -2 -8 -10 -13z m87 14 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-180 -30 c-3 -3 -12 -4 -19
-1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m358 -31 c12 -29 12 -33 -2 -33 -10 0 -13
6 -9 16 4 10 2 13 -6 8 -8 -5 -9 -2 -5 9 4 10 -1 21 -11 27 -16 10 -16 11 1 8
11 -2 25 -18 32 -35z m-95 18 c0 -6 12 -9 26 -8 20 2 30 -6 48 -39 20 -37 24
-40 34 -25 10 13 10 12 2 -6 -15 -33 -43 -29 -57 7 -9 23 -18 30 -40 31 -15 0
-22 3 -15 6 21 8 2 23 -31 24 -27 0 -29 2 -12 9 27 12 45 12 45 1z m-70 -26
c0 -8 -7 -15 -15 -15 -13 0 -14 3 -3 15 16 18 18 18 18 0z m43 -11 c-3 -9 0
-20 7 -24 17 -11 3 -31 -17 -23 -13 5 -15 1 -9 -23 4 -16 8 -38 9 -49 1 -11 6
-27 11 -35 9 -13 8 -13 -7 -1 -9 7 -17 20 -17 27 0 8 -7 14 -15 14 -8 0 -15 6
-15 14 0 8 -10 16 -22 19 -23 4 -23 4 2 15 14 6 19 11 11 11 -11 1 -11 4 0 18
13 15 11 16 -11 10 -14 -4 -33 -7 -42 -7 -10 0 -18 -4 -18 -10 0 -5 8 -10 18
-10 14 0 15 -2 2 -10 -21 -13 -30 -12 -27 3 1 6 -4 12 -10 11 -7 -1 -22 -2
-33 -2 -14 -1 -20 -8 -18 -18 2 -10 -3 -19 -11 -22 -8 -3 -11 0 -7 7 5 7 -1
11 -16 12 -23 1 -23 1 2 9 l25 8 -25 2 c-14 1 -41 8 -60 15 -33 13 -34 14 -8
14 15 1 29 -3 32 -8 9 -13 86 -11 143 5 28 8 62 12 76 9 19 -5 25 -2 30 14 3
12 10 21 16 21 6 0 8 -7 4 -16z m-184 -20 c10 -9 -76 -3 -89 7 -11 7 -1 8 35
3 28 -4 52 -8 54 -10z m362 -16 c7 -17 14 -45 14 -62 1 -17 7 -44 14 -60 6
-16 10 -34 8 -40 -2 -6 -8 5 -12 24 -8 34 -9 34 -16 10 -5 -18 -8 -9 -9 35 -1
33 -2 66 -1 72 1 8 -5 10 -16 7 -14 -5 -15 -4 -4 4 12 9 12 13 2 26 -8 9 -9
16 -3 16 5 0 15 -14 23 -32z m-101 -25 c0 -7 7 -13 15 -13 8 0 17 -11 21 -25
3 -14 10 -25 15 -25 5 0 9 -8 9 -17 0 -15 -2 -16 -10 -3 -9 14 -15 6 -10 -15
1 -5 -8 -1 -19 9 -16 14 -18 22 -10 32 6 7 8 18 4 24 -11 18 -45 11 -68 -14
l-23 -25 1 37 c0 30 4 37 25 42 38 10 50 8 50 -7z m-380 -3 c0 -5 -5 -10 -11
-10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m470 -56 c0 -19 -4 -34
-10 -34 -5 0 -10 11 -10 25 0 14 -4 25 -10 25 -5 0 -10 6 -10 14 0 9 7 12 20
9 15 -4 20 -14 20 -39z m-550 -4 c-10 -24 -15 -28 -26 -19 -8 6 -14 16 -14 21
0 6 6 5 15 -2 12 -10 15 -7 21 15 3 14 9 24 12 21 2 -3 -1 -19 -8 -36z m115
-8 c-8 -8 -25 10 -25 26 0 13 2 13 14 -4 8 -10 13 -21 11 -22z m-155 -37 c0
-5 -5 -3 -10 5 -5 8 -10 20 -10 25 0 6 5 3 10 -5 5 -8 10 -19 10 -25z m2741
-56 c20 -20 19 -48 -3 -76 -15 -19 -62 -16 -86 6 -22 18 -32 68 -12 56 6 -3
10 1 10 9 0 14 17 23 53 25 9 0 26 -8 38 -20z m-2248 -6 c-7 -2 -19 -2 -25 0
-7 3 -2 5 12 5 14 0 19 -2 13 -5z m2235 -489 c22 -15 29 -61 16 -110 -11 -40
-103 -34 -112 6 -10 41 -10 53 -2 76 15 40 62 53 98 28z m-33 -664 c3 -5 0
-10 -7 -10 -11 0 -10 -2 0 -9 10 -6 9 -10 -7 -14 -13 -3 -21 -13 -21 -27 0
-11 -4 -19 -9 -16 -5 4 -12 0 -14 -6 -3 -7 -6 -3 -6 9 -1 33 21 77 31 61 6
-10 8 -8 8 5 0 19 15 23 25 7z m-18 -62 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m198 12 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16
10 6 0 7 -4 4 -10z m-1831 -862 c20 -28 20 -52 2 -67 -13 -11 -12 -15 5 -32
24 -24 24 -50 0 -80 -15 -19 -30 -24 -85 -27 l-66 -4 -1 28 c0 16 -2 65 -3
109 -2 44 -1 83 1 88 2 4 33 7 68 7 55 0 66 -3 79 -22z m159 -65 l57 -88 0 88
c0 71 3 87 15 87 12 0 15 -20 15 -116 0 -105 -2 -115 -17 -112 -10 2 -43 41
-73 88 l-55 84 -3 -87 c-2 -68 -6 -87 -17 -87 -12 0 -15 21 -15 115 0 105 2
115 18 115 12 0 38 -30 75 -87z m433 62 c14 -21 15 -30 5 -52 -10 -21 -9 -29
4 -43 22 -24 18 -57 -10 -85 -31 -32 -66 -32 -102 -1 -21 19 -25 27 -16 36 8
9 16 7 32 -9 25 -25 44 -27 65 -5 28 27 12 64 -26 64 -10 0 -18 7 -18 15 0 8
6 15 14 15 20 0 37 25 30 44 -8 20 -47 21 -64 1 -13 -16 -40 -21 -40 -7 0 4 7
18 16 30 23 33 88 31 110 -3z m154 -91 c0 -88 -3 -115 -12 -112 -9 3 -14 32
-16 86 -3 75 -11 98 -27 72 -8 -13 -25 -13 -25 0 0 11 60 69 73 70 4 0 7 -52
7 -116z m206 91 c16 -24 12 -65 -7 -65 -6 0 -2 -11 9 -25 43 -54 -8 -125 -78
-110 -49 11 -68 78 -33 117 9 10 11 18 6 18 -18 0 -23 44 -7 67 21 34 87 32
110 -2z m178 3 c28 -40 20 -68 -34 -120 -27 -26 -50 -50 -50 -53 0 -3 23 -5
50 -5 38 0 50 -4 50 -15 0 -12 -15 -15 -75 -15 -96 0 -98 13 -14 90 48 44 60
62 57 81 -2 19 -10 25 -36 27 -22 2 -34 -2 -38 -12 -9 -23 -34 -20 -34 4 0 43
97 57 124 18z m186 7 c0 -11 -12 -15 -45 -15 -25 0 -45 -4 -45 -9 0 -5 -3 -16
-6 -25 -5 -12 0 -14 28 -10 46 8 78 -23 78 -76 0 -73 -77 -104 -128 -53 -23
24 -25 28 -10 31 10 2 25 -4 34 -12 18 -19 28 -20 55 -6 22 12 26 59 7 78 -15
15 -54 16 -63 2 -3 -5 -13 -10 -22 -10 -12 0 -14 9 -8 53 4 28 9 55 12 60 2 4
29 7 59 7 41 0 54 -4 54 -15z m190 6 c0 -5 -13 -31 -30 -58 -16 -26 -36 -73
-43 -103 -16 -60 -45 -79 -38 -25 4 38 39 133 52 140 21 14 6 24 -38 27 -26 2
-48 9 -51 16 -3 9 16 12 72 12 42 0 76 -4 76 -9z m164 -13 c9 -12 16 -26 16
-30 0 -14 -27 -9 -40 7 -26 31 -65 15 -75 -30 -6 -25 -5 -28 6 -17 20 18 67
14 89 -8 49 -49 3 -139 -65 -126 -50 11 -60 28 -60 112 0 68 3 78 24 95 33 27
86 25 105 -3z m-1334 -54 c0 -9 -7 -14 -17 -12 -25 5 -28 28 -4 28 12 0 21 -6
21 -16z m0 -140 c0 -9 -7 -14 -17 -12 -25 5 -28 28 -4 28 12 0 21 -6 21 -16z"/>
<path d="M3420 3180 c10 -6 -93 -8 -285 -7 -165 1 -317 -1 -337 -4 -21 -3 -39
-1 -41 4 -2 7 -15 6 -35 -1 -21 -7 -30 -7 -26 -1 4 7 -74 9 -236 8 -153 -1
-236 -5 -228 -11 9 -7 7 -8 -8 -5 -12 2 -29 0 -39 -6 -12 -6 -16 -6 -12 0 8
12 -86 23 -187 22 -160 -1 -177 -26 -177 -260 0 -185 -10 -223 -56 -227 -62
-5 -72 27 -74 242 -1 173 -4 186 -35 186 -2 0 -3 -109 -2 -242 l3 -243 103 -3
102 -3 0 250 c0 155 4 251 10 253 5 2 81 2 167 0 l158 -3 -2 -672 -2 -672
-158 3 c-139 2 -158 4 -164 19 -5 15 -8 14 -19 -7 -14 -26 -116 -159 -169
-220 -32 -36 -39 -76 -21 -111 24 -43 70 0 70 66 0 71 77 173 121 162 12 -3
16 0 12 9 -7 20 51 51 86 46 34 -5 47 -35 25 -60 -8 -9 -26 -30 -40 -47 -17
-20 -24 -23 -20 -11 6 16 4 17 -9 6 -8 -7 -16 -10 -16 -6 -1 3 -2 10 -3 16 0
5 -7 15 -13 21 -10 10 -13 8 -13 -14 0 -15 -9 -50 -20 -79 -24 -64 -24 -58 -1
-58 32 0 181 159 196 210 9 29 22 40 51 40 19 0 22 -8 28 -61 13 -129 -2 -182
-66 -233 -34 -27 -28 -33 16 -16 32 12 116 88 116 105 0 23 31 75 69 114 54
56 103 78 164 73 38 -4 12 -105 -39 -153 -25 -24 -26 -24 -40 -5 -14 19 -16
19 -54 -19 -57 -55 -53 -115 8 -115 31 0 57 14 122 68 30 25 57 47 59 49 2 1
-2 9 -8 17 -19 22 3 79 45 122 39 38 64 44 64 14 0 -18 6 -20 56 -18 39 2 58
-2 64 -12 18 -28 10 -62 -24 -103 -18 -23 -38 -52 -45 -64 -15 -30 -14 -63 2
-63 27 0 87 55 96 88 21 74 90 162 137 176 14 4 28 1 38 -9 14 -14 14 -20 1
-51 -8 -19 -35 -59 -60 -90 -60 -74 -59 -115 3 -114 33 1 159 140 146 161 -4
7 -3 9 4 5 10 -6 112 124 112 144 0 6 -28 10 -65 10 -51 0 -72 -5 -93 -20 -30
-22 -62 -18 -62 7 0 11 -9 14 -35 11 -20 -2 -36 1 -36 7 -1 5 -2 82 -3 170
l-1 159 -120 1 c-94 1 -119 4 -117 15 2 7 19 46 39 86 l37 74 65 0 66 0 -3
128 -3 128 70 142 c39 79 71 145 71 148 0 3 -24 4 -52 3 l-53 -3 -1 139 c-1
77 2 142 6 145 5 3 116 4 249 2 l241 -3 0 -119 0 -119 -136 -278 c-75 -153
-162 -331 -195 -396 -32 -65 -59 -120 -59 -123 0 -2 91 -5 203 -6 l202 -3 0
-150 0 -150 -55 3 c-65 4 -69 1 -124 -101 l-37 -68 56 45 c85 70 125 73 125
12 0 -46 -24 -101 -72 -164 -41 -55 -43 -68 -10 -58 22 7 164 149 193 193 l19
28 -37 5 c-28 4 -39 10 -41 25 -2 12 0 16 5 9 5 -7 20 5 40 33 l33 43 -30 0
-30 0 0 170 0 170 -125 0 c-148 0 -139 -11 -133 157 l4 108 117 237 117 237 0
130 0 130 98 3 c68 2 91 6 77 12 -11 5 -32 18 -47 28 -14 10 -30 18 -35 18 -4
0 -1 -5 7 -10z m-670 -14 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14
25 6z m10 -181 l0 -145 -205 0 -206 0 3 -102 3 -103 185 0 185 0 3 -147 3
-148 -191 0 -190 0 0 -125 0 -125 203 -2 202 -3 0 -148 0 -148 -265 0 -265 0
-3 670 -2 671 270 0 270 0 0 -145z m-10 -175 c0 -5 7 -7 15 -4 8 4 15 1 15 -6
0 -7 7 -10 15 -6 17 6 21 -9 5 -19 -5 -3 -10 -21 -10 -39 0 -60 -5 -61 -224
-51 -130 6 -166 18 -166 58 1 51 15 55 178 58 84 2 152 7 152 11 0 4 5 8 10 8
6 0 10 -4 10 -10z m-225 -519 c16 -6 19 -15 16 -46 -6 -72 -64 -120 -114 -94
-31 17 -37 28 -37 74 0 22 4 45 8 51 12 20 91 29 127 15z m476 -108 c-12 -20
-14 -14 -5 12 4 9 9 14 11 11 3 -2 0 -13 -6 -23z m-816 -424 c5 9 11 9 22 0
12 -10 16 -10 24 2 8 11 9 10 7 -6 -2 -13 2 -19 12 -17 10 3 9 -3 -5 -19 -39
-45 -65 -70 -65 -62 0 4 -8 -2 -18 -15 -18 -23 -18 -22 -16 42 2 69 11 97 24
76 6 -9 9 -9 15 -1z m598 -8 c4 0 7 -6 7 -12 0 -5 -5 -7 -12 -3 -8 5 -9 2 -4
-12 4 -10 4 -15 0 -11 -4 3 -14 -1 -23 -11 -15 -16 -18 -15 -43 11 -15 15 -22
27 -15 26 25 -3 38 1 32 10 -5 8 13 9 58 2z m221 -26 c8 -21 -13 -57 -66 -117
-15 -16 -29 -35 -30 -40 -2 -6 -13 -8 -26 -3 -17 5 -20 10 -13 19 6 7 7 17 3
21 -4 5 1 5 10 2 11 -4 15 -2 12 7 -3 7 2 16 11 19 11 5 14 14 9 31 -5 20 1
29 32 50 45 30 50 31 58 11z m308 -17 c-3 -20 2 -33 19 -48 19 -16 20 -19 5
-13 -15 5 -17 3 -11 -8 4 -8 0 -7 -11 3 -10 10 -19 28 -20 40 -1 13 -5 31 -9
40 -5 13 -2 17 12 15 15 -1 18 -7 15 -29z m-951 -157 c-11 -10 -18 -20 -16
-23 3 -3 -6 -10 -20 -16 -17 -8 -25 -8 -25 -1 0 6 -4 7 -10 4 -5 -3 -10 -1
-10 4 0 6 8 11 18 11 14 0 15 2 2 10 -13 9 -13 10 0 11 8 1 18 1 23 0 4 0 7 3
7 9 0 5 7 7 15 4 8 -4 15 -1 15 6 0 6 5 8 10 5 7 -4 3 -14 -9 -24z"/>
<path d="M2710 3055 c-7 -14 -16 -23 -18 -20 -3 3 -10 1 -16 -3 -6 -4 -69 -5
-141 -1 l-130 5 133 2 c103 2 137 5 148 17 14 13 14 15 0 15 -8 0 -18 -4 -21
-10 -3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10 10 7 -45 10 -171 9 l-186 -1 -6
-375 c-4 -207 -7 -480 -8 -607 0 -127 -3 -239 -7 -248 -4 -10 -7 37 -7 102 0
66 -3 120 -6 120 -4 0 -6 -56 -6 -125 l1 -125 243 0 243 0 0 130 c0 123 -1
130 -20 130 -22 0 -27 -15 -7 -23 6 -3 -64 -6 -157 -6 -154 -1 -168 0 -150 14
14 11 15 15 4 14 -8 -1 -30 -1 -48 0 l-33 1 3 143 3 142 188 3 187 2 0 130 c0
80 -4 130 -10 130 -5 0 -10 -9 -10 -20 0 -19 -7 -20 -155 -20 -148 0 -155 1
-155 20 0 15 -7 20 -25 20 l-25 0 0 125 0 125 180 0 c113 0 180 4 180 10 0 6
-4 10 -10 10 -5 0 -10 8 -10 18 0 14 2 15 10 2 6 -10 10 5 13 50 l3 65 4 -65
4 -65 6 75 6 75 2 -89 c1 -49 4 -87 7 -84 8 7 11 208 4 208 -3 0 -12 -11 -19
-25z m-343 -21 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m290
-86 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z m-284 37 c-4 -15
-36 -12 -41 3 -3 9 3 13 19 10 13 -1 23 -7 22 -13z m-66 -47 c-3 -8 -6 -5 -6
6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m73 -4 c0 -11 -5 -14 -16 -10 -9 3 -18 6
-20 6 -3 0 -2 5 1 10 10 16 35 12 35 -6z m130 7 l45 -8 -53 -2 c-33 -1 -51 -5
-47 -11 4 -6 -9 -10 -32 -9 -30 0 -34 2 -18 9 18 8 18 9 2 9 -10 1 -15 6 -12
11 7 12 51 12 115 1z m-154 -33 c4 -7 3 -8 -4 -4 -7 4 -12 1 -12 -6 0 -7 -9
-19 -20 -26 -16 -10 -20 -10 -20 2 0 8 7 17 16 20 8 3 12 10 9 16 -3 5 1 10 9
10 8 0 18 -5 22 -12z m187 5 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2
23 -4z m-135 -16 c23 3 24 2 7 -6 -31 -15 -45 -13 -44 7 0 9 3 12 5 6 3 -6 17
-9 32 -7z m251 -3 c2 -2 -43 -3 -100 -2 -93 1 -98 2 -44 8 57 6 135 3 144 -6z"/>
<path d="M2430 2058 c0 -5 60 -7 133 -6 133 2 117 5 -46 10 -48 2 -87 0 -87
-4z"/>
<path d="M2766 2703 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M1615 3153 c-48 -7 -54 -23 -9 -23 30 0 41 4 41 15 0 8 -1 14 -1 14
-1 -1 -14 -4 -31 -6z"/>
<path d="M3801 3095 c-2 -11 -3 -22 -3 -25 0 -3 -90 -5 -201 -5 l-202 0 -2
-115 c0 -63 1 -107 4 -97 3 12 10 16 21 12 14 -5 14 -4 -1 12 -15 18 -15 20 5
32 19 12 20 11 15 -8 -3 -12 -1 -24 4 -27 5 -3 9 2 9 10 0 10 9 16 25 16 14 0
25 -4 25 -9 0 -6 -9 -8 -20 -6 -11 2 -20 -1 -20 -6 0 -6 61 -9 160 -8 87 0
164 -2 170 -6 17 -11 21 16 17 136 -1 63 -4 105 -6 94z m-304 -61 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m151 -1 c-21 -2 -57 -2 -80 0 -24
2 -7 4 37 4 44 0 63 -2 43 -4z m132 -23 c8 -5 11 -12 7 -17 -5 -4 -12 -1 -17
7 -7 10 -10 11 -10 3 0 -15 -36 -18 -45 -4 -3 5 3 12 12 14 29 8 37 7 53 -3z
m-282 -22 c-3 -7 -21 -14 -41 -15 -40 -1 -51 13 -14 20 44 8 59 7 55 -5z m-42
-38 c32 0 44 -4 44 -14 0 -8 -4 -18 -10 -21 -5 -3 -10 -2 -10 3 0 18 -33 23
-50 7 -22 -20 -30 -10 -29 34 1 23 3 28 6 14 4 -20 11 -23 49 -23z m299 -10
c3 -5 12 -7 20 -3 9 3 15 -1 15 -11 0 -18 -15 -22 -25 -6 -8 13 -51 13 -77 -1
-14 -8 -23 -8 -31 0 -13 13 -61 15 -53 3 3 -5 -15 -7 -40 -6 -24 2 -44 -1 -44
-6 0 -5 35 -11 78 -13 l77 -3 -83 -2 c-81 -2 -83 -1 -80 21 2 18 11 23 48 28
75 10 188 10 195 -1z m-35 -30 c-12 -8 -9 -10 13 -10 20 0 26 4 21 13 -4 6 3
3 17 -8 13 -11 25 -20 27 -21 1 -1 -15 0 -35 1 -89 5 -85 4 -71 20 7 8 20 14
28 14 13 1 13 0 0 -9z"/>
<path d="M1612 3088 c-2 -18 -18 -21 -92 -19 -29 1 -32 0 -17 -9 9 -6 14 -16
11 -22 -5 -7 -2 -8 7 -2 8 5 22 7 32 6 9 -2 17 2 17 7 0 6 5 11 10 11 7 0 7
-6 1 -18 -12 -23 -6 -108 9 -127 8 -10 15 -65 18 -155 l5 -140 -127 0 c-75 0
-126 -4 -126 -10 0 -5 44 -53 98 -107 131 -130 144 -164 141 -352 -1 -72 -6
-134 -10 -137 -4 -2 -6 -10 -4 -17 3 -7 1 -25 -4 -42 -8 -27 -9 -28 -17 -11
-5 13 -3 21 4 24 7 2 12 10 12 19 0 13 -2 13 -15 -1 -8 -8 -15 -22 -15 -30 0
-9 -4 -16 -9 -16 -4 0 -6 -11 -3 -25 3 -20 1 -24 -12 -19 -9 3 -16 1 -16 -5 0
-5 -9 -19 -20 -29 -16 -14 -17 -19 -5 -19 8 0 15 5 15 11 0 6 5 4 11 -4 8 -12
9 -9 4 10 -4 16 -3 20 3 12 6 -9 11 -7 20 10 6 13 13 32 15 43 3 14 5 12 6 -8
2 -42 -45 -98 -74 -91 -13 3 -25 1 -29 -4 -3 -6 28 -10 79 -11 l85 -2 0 271 0
270 124 0 c122 0 125 -1 128 -22 2 -16 9 -22 21 -20 15 3 17 -8 17 -101 0 -82
-3 -106 -14 -110 -8 -3 -17 -1 -20 6 -2 7 -5 -8 -5 -33 -1 -34 2 -41 11 -32 7
7 20 12 30 12 29 0 23 -73 -12 -148 -17 -35 -30 -70 -30 -78 0 -11 28 -14 150
-14 l150 0 0 645 c0 466 -4 645 -12 645 -7 0 -9 -19 -5 -57 3 -37 2 -49 -3
-33 -7 23 -8 23 -10 -10 l-2 -35 -9 43 c-4 23 -11 47 -15 53 -7 12 -194 9
-194 -3 0 -5 5 -8 10 -8 7 0 7 -7 2 -17 -5 -10 -10 -112 -11 -228 -2 -115 -5
-213 -7 -217 -3 -5 -59 -8 -125 -8 -112 0 -119 1 -119 20 0 15 -7 20 -25 20
l-25 0 -3 243 c-1 133 -3 234 -5 225z m-11 -88 c-5 -40 -8 -49 -13 -32 -5 19
9 100 16 90 2 -1 0 -28 -3 -58z m424 41 c34 -7 35 -9 10 -9 -16 0 -43 4 -60 8
-42 11 -1 11 50 1z m86 -13 c11 -21 11 -22 -4 -9 -10 7 -17 17 -17 22 0 15 9
10 21 -13z m-135 -87 c-4 -36 -9 -67 -12 -70 -9 -9 -13 6 -20 70 -6 62 -5 65
16 65 21 0 22 -3 16 -65z m21 7 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2
-33 0 -45z m90 -65 c-2 -16 -4 -5 -4 22 0 28 2 40 4 28 2 -13 2 -35 0 -50z
m-90 5 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m110 -10 c-3 -8
-6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M1950 2960 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3250 3085 c0 -13 -27 -15 -181 -15 -99 0 -178 -4 -175 -8 2 -4 1
-14 -5 -22 -7 -12 -9 -9 -9 9 0 13 -3 21 -7 17 -5 -4 -9 -50 -10 -103 l-1 -95
34 4 c19 2 34 0 34 -5 0 -6 11 2 25 17 14 15 33 26 43 24 12 -3 6 -11 -25 -31
-49 -31 -55 -47 -18 -47 16 0 25 -6 25 -15 0 -8 -4 -15 -9 -15 -5 0 -12 -12
-16 -27 -3 -15 -10 -30 -13 -33 -4 -3 -31 -52 -61 -110 l-53 -105 -5 -125 c-2
-69 -6 -131 -9 -138 -3 -10 -20 -12 -63 -10 l-58 4 -24 -46 c-13 -25 -28 -48
-33 -52 -16 -11 33 -18 108 -16 l69 2 4 -34 c3 -36 13 -55 13 -27 0 9 3 17 8
17 4 0 8 -50 8 -111 2 -120 -7 -137 -17 -31 -5 58 -6 52 -6 -40 l-1 -108 28 0
c19 0 30 7 38 25 13 28 60 45 72 26 4 -7 5 -22 3 -34 -4 -21 -2 -22 75 -19 64
3 84 7 98 23 18 20 63 26 65 9 1 -5 2 -14 3 -20 0 -5 4 -10 9 -10 4 0 23 0 42
0 l35 0 0 129 0 129 -50 -1 c-27 -1 -50 -5 -50 -8 0 -4 13 -6 30 -4 16 2 32 0
35 -6 4 -5 -59 -9 -164 -9 -147 0 -170 2 -165 15 5 11 -1 15 -25 15 -17 0 -31
2 -31 4 0 6 131 277 249 516 45 91 79 171 76 178 -3 7 -1 11 4 8 8 -5 49 74
43 83 -2 2 2 7 10 12 16 10 24 229 8 229 -6 0 -10 -7 -10 -15z m-315 -45 c3
-5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m307 -8
c-10 -3 -10 -7 0 -20 12 -15 11 -16 -4 -3 -14 11 -18 11 -18 1 0 -8 -2 -11 -5
-9 -2 3 -2 14 1 26 4 15 11 20 22 15 11 -4 12 -7 4 -10z m-89 1 c-34 -2 -93
-2 -130 0 -37 2 -9 3 62 3 72 0 102 -1 68 -3z m-253 -128 c6 4 10 0 10 -8 0
-10 -7 -13 -21 -10 -12 4 -18 11 -15 20 3 8 7 38 8 66 2 50 2 50 5 -11 2 -36
8 -60 13 -57z m7 38 c-2 -16 -4 -5 -4 22 0 28 2 40 4 28 2 -13 2 -35 0 -50z
m20 15 c-2 -13 -4 -5 -4 17 -1 22 1 32 4 23 2 -10 2 -28 0 -40z m74 -8 c21 0
39 -4 39 -10 0 -5 18 -10 40 -10 37 0 45 7 41 33 -3 17 31 5 36 -13 3 -11 14
-20 24 -20 32 0 21 -17 -15 -24 -19 -4 -39 -13 -46 -21 -7 -8 -25 -15 -41 -15
-22 0 -29 5 -29 20 0 24 -34 43 -66 37 -13 -2 -24 -1 -24 4 0 4 -2 13 -3 21
-2 7 -2 10 0 6 3 -5 22 -8 44 -8z m245 -17 c-11 -11 -17 4 -9 23 7 18 8 18 11
1 2 -10 1 -21 -2 -24z m-29 -5 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12
1 -19z m-267 -10 c0 -4 -6 -8 -12 -9 -7 0 -4 -4 6 -8 18 -7 18 -8 3 -14 -9 -3
-17 -3 -18 1 0 4 -2 14 -4 23 -1 9 4 16 11 16 8 0 14 -4 14 -9z m294 -6 c3 -5
-1 -9 -9 -9 -8 0 -12 4 -9 9 3 4 7 8 9 8 2 0 6 -4 9 -8z m-4 -33 c0 -11 -27
-12 -34 -1 -2 4 4 9 15 9 10 1 19 -2 19 -8z m-33 -21 c-2 -7 -11 -12 -21 -10
-9 2 -16 -2 -16 -8 0 -6 -5 -8 -11 -5 -6 5 -4 12 8 21 23 17 46 18 40 2z
m-177 -8 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16
-10z m186 -22 c-9 -12 -16 -19 -16 -15 0 11 24 47 28 42 2 -2 -3 -14 -12 -27z"/>
<path d="M1415 3070 c-4 -6 2 -21 13 -33 46 -51 82 -167 82 -266 l0 -61 -129
0 -129 0 -21 -40 c-12 -22 -21 -42 -21 -44 0 -2 31 -24 69 -49 169 -114 228
-200 237 -349 10 -146 -24 -269 -97 -352 -37 -43 -94 -82 -133 -92 -35 -8 -40
-20 -22 -46 9 -12 16 -31 16 -42 0 -19 1 -19 20 2 29 31 135 29 109 -1 -5 -7
-24 -32 -43 -57 -29 -39 -32 -49 -21 -63 7 -9 15 -17 18 -17 3 0 36 37 73 83
86 104 89 107 108 107 27 0 20 -66 -13 -113 -66 -98 -89 -167 -53 -167 34 0
87 70 106 140 32 118 93 221 200 337 l67 72 2 156 2 155 -107 0 -108 0 0 -270
0 -270 -109 0 c-126 0 -139 9 -86 60 121 118 148 427 50 579 -28 43 -105 118
-158 152 -33 21 -37 28 -35 58 l3 35 116 -3 116 -2 7 55 c12 103 -29 274 -76
319 -34 32 -46 38 -53 27z m380 -844 c9 -47 -2 -216 -14 -226 -9 -7 -45 -65
-43 -68 1 -1 -3 -9 -10 -16 -9 -10 -17 -11 -25 -4 -7 6 -14 7 -14 2 -1 -5 -5
7 -8 26 -4 19 -6 96 -4 170 3 131 4 136 28 157 24 21 28 22 55 8 20 -11 31
-25 35 -49z m-345 -468 c0 -4 -5 -10 -12 -14 -8 -5 -6 -10 8 -15 18 -7 18 -8
-2 -8 -34 -1 -73 22 -60 36 12 12 66 13 66 1z m-130 -8 c0 -5 -2 -10 -4 -10
-3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z"/>
<path d="M4340 3063 c0 -10 -4 -14 -8 -8 -9 13 -32 17 -32 6 0 -5 3 -11 8 -13
4 -1 4 -41 1 -88 l-5 -85 -2 81 -2 81 -147 -2 -148 -3 135 8 c162 9 153 8 145
19 -3 6 -79 9 -177 8 l-172 -2 -2 -125 c-1 -69 -2 -140 -3 -157 l-1 -33 -115
0 -115 0 0 -25 c0 -24 3 -25 58 -26 31 -1 66 -2 77 -3 13 -1 21 -9 23 -23 2
-15 10 -23 23 -23 19 0 20 -5 15 -87 -2 -49 -5 -139 -5 -201 l-1 -113 -87 1
c-49 1 -93 1 -100 1 -6 -1 -13 -22 -15 -51 -4 -61 0 -63 115 -58 75 2 82 1 86
-17 2 -11 9 -24 17 -28 11 -6 14 -39 14 -147 l0 -140 220 0 220 0 0 131 c0
131 0 131 -22 125 -13 -3 -88 -7 -168 -8 l-145 -2 160 -6 160 -6 -162 -2
c-139 -2 -163 0 -163 13 0 10 -11 15 -35 15 l-35 0 0 145 0 145 190 0 190 0 0
124 c0 73 -4 127 -10 131 -5 3 -10 -3 -10 -14 0 -20 -5 -21 -155 -21 -148 0
-155 1 -155 20 0 15 -7 20 -25 20 l-25 0 0 125 0 126 201 -3 c186 -3 200 -2
195 15 -2 9 0 15 5 11 5 -3 9 36 9 90 0 57 -4 96 -10 96 -5 0 -10 -8 -10 -17z
m-364 -46 c10 4 14 -3 16 -26 2 -52 3 -56 12 -63 6 -4 4 -13 -4 -23 -10 -13
-15 -13 -22 -3 -6 10 -10 10 -18 -2 -8 -13 -10 -12 -10 3 0 9 5 17 10 17 6 0
10 5 10 11 0 5 -4 7 -10 4 -5 -3 -10 3 -10 14 0 12 3 20 8 19 4 -2 7 2 7 7 0
6 -3 9 -7 8 -5 -2 -7 14 -7 35 1 22 3 28 6 15 3 -14 9 -20 19 -16z m351 -79
c-2 -18 -4 -6 -4 27 0 33 2 48 4 33 2 -15 2 -42 0 -60z m-269 55 c-2 -10 -10
-18 -18 -18 -8 0 -16 8 -18 18 -2 12 3 17 18 17 15 0 20 -5 18 -17z m182 -8
c0 -28 -5 -31 -24 -13 -9 9 -10 16 -2 25 17 19 26 15 26 -12z m37 -7 c-3 -7
-5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-80 -41 c2 -3 -7 -7 -20 -9
-15 -3 -24 0 -24 8 0 11 33 11 44 1z m50 -2 c8 -9 13 -19 10 -22 -6 -5 -37 21
-37 31 0 10 11 6 27 -9z m30 -37 c-2 -13 -4 -5 -4 17 -1 22 1 32 4 23 2 -10 2
-28 0 -40z m-227 1 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3
10 -10 10 -16z m93 -6 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z"/>
<path d="M2127 3050 c3 -11 7 -20 9 -20 2 0 4 9 4 20 0 11 -4 20 -9 20 -5 0
-7 -9 -4 -20z"/>
<path d="M1536 3022 c6 -4 14 -14 17 -22 4 -10 6 -8 6 8 1 15 -5 22 -16 22
-11 0 -14 -3 -7 -8z"/>
<path d="M882 3008 c-14 -14 -16 -28 -2 -28 5 0 14 9 20 20 12 22 1 27 -18 8z"/>
<path d="M1061 2881 c-43 -43 -36 -102 19 -165 33 -38 60 -47 60 -21 0 9 11
38 25 65 30 60 31 80 4 114 -25 32 -79 36 -108 7z"/>
<path d="M3380 2812 c0 -10 -4 -22 -10 -28 -9 -9 -30 -49 -169 -319 -55 -108
-56 -112 -61 -213 l-5 -102 113 0 112 0 0 -25 c0 -14 4 -25 9 -25 21 0 36
-151 21 -225 -8 -45 -9 -41 -5 35 4 99 -2 89 -13 -22 l-8 -77 54 -7 c52 -6 55
-5 91 30 25 24 46 36 65 36 l28 0 -20 -35 -21 -34 67 6 c57 5 69 10 90 35 33
39 66 35 70 -10 2 -18 8 -30 13 -27 5 4 9 65 9 136 l0 130 -27 -5 c-16 -2 -89
-6 -163 -7 l-135 -3 150 -6 150 -6 -162 -2 c-139 -2 -163 0 -163 13 0 10 -10
15 -30 15 l-30 0 0 145 0 145 190 0 190 0 0 124 c0 73 -4 127 -10 131 -5 3
-10 -3 -10 -14 0 -20 -5 -21 -155 -21 -148 0 -155 1 -155 20 0 15 -7 20 -25
20 l-25 0 0 105 c0 63 -4 105 -10 105 -5 0 -10 -8 -10 -18z"/>
<path d="M976 2238 c-7 -16 -23 -18 -111 -18 -115 0 -110 3 -115 -75 -4 -55
-3 -66 5 -95 l6 -25 -15 25 c-19 31 -20 17 -2 -34 11 -32 15 -36 19 -20 4 12
5 8 5 -11 -1 -31 20 -85 32 -85 4 0 15 12 25 26 18 27 146 94 181 94 31 0 33
7 13 48 -11 20 -20 57 -20 82 -1 25 -5 59 -9 75 -6 25 -8 27 -14 13z"/>
<path d="M1871 2134 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M720 2124 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M1220 2130 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2986 2062 c-3 -5 1 -9 9 -9 8 0 12 4 9 9 -3 4 -7 8 -9 8 -2 0 -6 -4
-9 -8z"/>
<path d="M3030 2060 c-12 -7 -10 -9 8 -7 12 0 22 5 22 9 0 11 -12 10 -30 -2z"/>
<path d="M1565 2040 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"/>
<path d="M3372 2025 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M3895 2031 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M3890 1931 c0 -39 3 -72 8 -74 4 -3 7 28 7 69 0 41 -3 74 -7 74 -5 0
-8 -31 -8 -69z"/>
<path d="M1830 1918 c-67 -90 -159 -248 -146 -248 8 0 141 191 160 228 24 47
15 60 -14 20z"/>
<path d="M3890 1820 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M1967 1588 c-48 -53 -56 -68 -36 -68 6 0 18 -9 27 -21 l17 -20 22 20
c26 25 39 79 26 116 l-8 26 -48 -53z"/>
<path d="M3879 1617 c-57 -49 -92 -125 -69 -152 28 -34 80 -2 80 48 0 15 9 49
19 77 21 56 12 64 -30 27z"/>
<path d="M3134 1597 c-16 -14 -44 -45 -62 -71 l-33 -46 30 0 c25 0 37 8 61 42
30 41 47 84 37 94 -2 3 -17 -6 -33 -19z"/>
<path d="M2549 1603 c-16 -2 -40 -21 -60 -46 -49 -60 -129 -140 -166 -165 -44
-31 -96 -29 -122 4 -11 14 -23 41 -27 60 -3 19 -8 34 -11 34 -2 0 -16 -14 -30
-30 -31 -38 -101 -87 -136 -95 -41 -11 -95 21 -120 71 -17 36 -23 41 -38 33
-10 -5 -28 -9 -40 -9 -13 0 -38 -18 -63 -44 -22 -25 -53 -48 -68 -51 -35 -9
-74 0 -76 17 -1 20 -20 89 -24 94 -3 2 -20 -14 -38 -37 -26 -32 -30 -43 -19
-50 8 -5 16 -3 21 4 5 8 16 3 36 -16 32 -31 102 -49 102 -27 0 7 3 10 7 7 3
-4 28 9 55 28 27 20 54 33 60 29 7 -4 8 -3 4 5 -15 24 41 3 99 -38 57 -40 107
-53 120 -31 3 6 15 10 26 10 11 0 22 7 25 15 4 8 12 15 20 15 8 0 14 5 14 10
0 13 47 13 55 0 4 -6 11 -7 17 -4 7 5 9 1 5 -9 -3 -9 -1 -19 5 -23 7 -5 8 -2
4 7 -4 8 0 5 8 -5 23 -28 101 -31 127 -5 10 10 26 19 35 19 8 0 13 4 9 9 -3 5
4 13 15 16 12 4 25 18 31 32 6 16 15 23 24 20 9 -4 12 -1 8 9 -3 7 0 14 6 14
7 0 22 -21 33 -47 22 -51 40 -73 58 -73 7 0 5 6 -4 16 -38 38 -28 113 26 191
16 24 25 42 21 42 -4 -1 -20 -4 -34 -6z"/>
<path d="M2989 1568 c-13 -18 -30 -40 -37 -48 -7 -8 -29 -35 -49 -60 -90 -113
-169 -121 -188 -17 l-7 38 -36 -42 c-20 -23 -50 -50 -66 -61 l-31 -20 30 7
c17 4 38 14 48 23 17 15 21 15 60 -7 22 -13 55 -24 71 -25 17 -1 40 -7 51 -13
17 -10 18 -15 8 -32 -15 -24 -17 -109 -3 -131 11 -18 16 -7 24 65 3 28 20 77
37 110 87 169 120 238 116 242 -3 2 -15 -11 -28 -29z"/>
<path d="M1287 1533 c-20 -38 -21 -43 -4 -43 6 0 21 12 31 27 11 14 14 23 8
20 -6 -4 -12 1 -14 10 -3 14 -8 11 -21 -14z"/>
<path d="M3258 1450 c-78 -81 -124 -100 -187 -82 -67 21 -78 17 -97 -35 -9
-27 -28 -74 -41 -107 -13 -32 -22 -61 -19 -64 3 -3 6 -1 6 4 0 6 8 19 18 30
10 10 19 21 19 24 9 41 16 60 21 56 2 -3 12 9 21 27 15 30 19 32 76 33 72 3
88 6 107 22 8 6 18 10 21 7 4 -3 5 -2 2 2 -5 6 24 32 38 33 5 0 5 -6 1 -12 -6
-10 -4 -10 8 0 14 11 18 10 27 -5 6 -10 11 -13 11 -8 0 6 5 3 10 -5 5 -8 13
-15 17 -16 4 0 21 -4 37 -9 19 -5 36 -4 49 4 11 7 16 8 12 2 -11 -20 4 -12 31
16 28 28 74 42 74 23 0 -6 5 -8 10 -5 6 3 10 1 10 -5 0 -6 4 -9 9 -5 8 4 -14
95 -23 95 -1 0 -26 -22 -55 -50 -55 -51 -109 -72 -140 -53 -20 13 -25 61 -12
109 6 21 9 39 8 40 -1 1 -32 -29 -69 -66z"/>
<path d="M1211 2914 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1265 2919 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M1760 670 c0 -28 2 -30 39 -30 42 0 65 18 55 44 -4 11 -19 16 -50 16
-42 0 -44 -1 -44 -30z"/>
<path d="M1760 571 c0 -41 0 -41 38 -41 50 0 64 11 60 46 -3 27 -6 29 -50 32
l-48 3 0 -40z"/>
<path d="M2744 685 c-8 -21 4 -35 32 -35 19 0 24 5 24 25 0 20 -5 25 -25 25
-14 0 -28 -7 -31 -15z"/>
<path d="M2748 609 c-22 -12 -23 -52 -3 -69 19 -16 45 -9 62 17 13 19 12 24
-6 42 -24 24 -29 25 -53 10z"/>
<path d="M3448 595 c-9 -21 -8 -28 7 -45 31 -34 75 -20 75 25 0 48 -62 63 -82
20z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
